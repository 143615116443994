<template>
	<div class="main-wrapper" data-app>
		<!-- Content  -->
		<div class="content" style="padding: 0px">
			<div class="container">
				<div class="row">
					<!-- Search -->
					<div class="col-md-12 grid-full-width page-search mb-0 mx-2">
						<div class="search__container">
							<v-row class="m-1">
								<div
									class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-4"
									style="padding: 6px"
								>
									<label for="" style="margin: auto">Palabras clave</label>
									<v-text-field
										solo
										required
										hide-details="auto"
										label="¿Qué estás buscando?"
										v-model="search"
										type="text"
										dense
									></v-text-field>
								</div>
								<div
									class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3"
									style="padding: 6px"
								>
									<label for="" style="margin: auto">País</label>
									<multiselect
										:disabled="companyType == 1"
										track-by="id"
										label="name"
										:cuntom-label="nameWithIso2"
										placeholder="Seleccionar países"
										v-model="selectedCountry"
										:options="countries"
										:multiple="false"
										:close-on-select="true"
										:clear-on-select="false"
										:preserve-search="false"
										><v-icon slot="append" color="red">
											mdi-map-marker-multiple
										</v-icon></multiselect
									>
								</div>
								<div
									class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3"
									style="padding: 6px"
								>
									<label for="" style="margin: auto">Categorías</label>
									<multiselect
										:disabled="companyType == 1 || companyType == 4"
										track-by="id"
										label="name"
										placeholder="Categorías"
										v-model="categoryId"
										:options="categories"
										:multiple="false"
										:close-on-select="true"
										:clear-on-select="false"
										:preserve-search="false"
										><v-icon slot="append" color="red">
											mdi-tag-multiple
										</v-icon></multiselect
									>
								</div>
								<div
									class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2"
									style="align-self: flex-end; padding: 6px"
								>
									<v-row class="m-0">
										<div class="search__button-container">
											<v-tooltip v-if="filterActivated" top>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														class="btn btn-primary px-2 py-1 search__button"
														v-bind="attrs"
														v-on="on"
														@click=";(filterActivated = false), getCompanies()"
													>
														<v-icon> mdi-backspace </v-icon>
													</v-btn>
												</template>
												<span>Eliminar Filtros</span>
											</v-tooltip>
											<v-tooltip top>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														class="btn btn-primary px-2 py-1 search__button"
														@click=";(filterActivated = true), getCompanies()"
														v-on="on"
														v-bind="attrs"
													>
														<v-icon> mdi-cloud-search </v-icon>
													</v-btn>
												</template>
												<span>Comenzar Busqueda</span>
											</v-tooltip>
										</div>
									</v-row>
								</div>
							</v-row>
						</div>
					</div>
					<!-- Search Section / End -->
					<div class="col-md-12" style="padding: 0px">
						<!-- Sorting - Filtering Section -->
						<div class="my-2">
							<div>
								<!-- Layout Switcher -->
								<div class="layout-switcher">
									<a class="list" @click="showGrid = false"
										><span
											class="round-pill d-block"
											style="color: #003a56; background-color: #fff"
											:class="{ active: !showGrid }"
											><i class="fa fa-align-justify"></i></span
									></a>
									<a class="grid" @click="showGrid = true"
										><span
											class="round-pill d-block"
											style="color: #003a56; background-color: #fff"
											:class="{ active: showGrid }"
											><i class="fa fa-th"></i></span
									></a>
								</div>
							</div>
						</div>
						<!-- Sorting - Filtering Section / End -->
						<!-- Listing Item -->
						<div class="col-12 col-md-12" v-if="!showGrid && !loading">
							<div class="list__container">
								<div class="items__container row" v-for="item of items" :key="item.id">
									<div class="list__item-img col-12 col-sm-4 col-md-3">
										<router-link class="item__img-container" :to="'/empresa/' + item.link"
											><img
												v-if="item.logo"
												:src="item.logo.route"
												class="item__img"
												:alt="item.name" />
											<img
												v-else
												:src="'/static/images/most-img-6.jpg'"
												class="item__img"
												alt="image"
										/></router-link>
									</div>
									<div class="list__item-content col-12 col-sm-8 col-md-9">
										<div class="item__content-block">
											<router-link :to="'/empresa/' + item.link">
												<h5 class="content__title" style="margin: 0px">
													{{ item.name }}
												</h5></router-link
											>
											<div class="content__description">
												<small>{{
													item.description.length > 200
														? item.description.slice(0, 200) + ' ...'
														: item.description
												}}</small>
											</div>
											<span
												v-for="category in item.categories"
												:key="category.id"
												class="badge badge-pill badge-primary text-uppercase badge-cat"
												>{{ category.name }}</span
											>
										</div>
										<router-link :to="'/empresa/' + item.link"
											><span
												class="round-pill like-banner like-banner--mobile d-block bg-primary"
												><i class="fa fa-search"></i></span
										></router-link>
									</div>
								</div>
							</div>
						</div>
						<!-- Listing Item / End -->
						<div class="row m-0 list__container--grid" v-else-if="showGrid && !loading">
							<div
								class="col-lg-3 col-md-4 col-sm-6 grid-layout-list"
								v-for="item in items"
								:key="item.id"
							>
								<!-- <div> -->
								<!-- <div class="list-cap-list"> -->
								<div class="list__item-img--grid">
									<router-link
										class="item__img-container--grid"
										:to="'/empresa/' + item.link"
										><img
											v-if="item.logo"
											:src="item.logo.route"
											class="item__img--grid"
											style="max-height: 130px; max-width: 100%"
											:alt="item.name" />
										<img
											v-else
											:src="'/static/images/most-img-6.jpg'"
											class="item__img--grid"
											style="height: 100%; width: 100%"
											:alt="item.name"
									/></router-link>
								</div>
								<div class="list__item-content--grid" style="padding: 5%">
									<span
										v-for="category in item.categories"
										:key="category.id"
										class="badge badge-pill badge-primary text-uppercase badge-cat"
										>{{ category.name }}</span
									>

									<router-link :to="'/empresa/' + item.link">
										<h5 class="mt-2">{{ item.name }}</h5></router-link
									>
									<div class="content__description--grid">
										<p style="margin: 0">
											{{
												item.description.length > 200
													? item.description.slice(0, 200) + ' ...'
													: item.description
											}}
										</p>
									</div>
								</div>
								<router-link :to="'/empresa/' + item.link"
									><span class="round-pill like-banner d-block bg-primary"
										><i class="fa fa-search"></i></span
								></router-link>
								<!-- </div> -->
								<!-- </div> -->
							</div>
						</div>
						<div
							class="row"
							v-if="
								!loading && message == 'No se encontraron resultados para esta búsqueda'
							"
						>
							<h4 style="margin: auto">
								{{ message }}
							</h4>
						</div>
						<div class="row" v-if="loading">
							<v-progress-circular
								style="margin: auto"
								indeterminate
								color="primary"
							></v-progress-circular>
						</div>
						<div class="row" v-if="loading">
							<h4 style="margin: auto">
								{{ message }}
							</h4>
						</div>
						<!-- Pagination -->
						<div class="text-center pt-2" style="margin: 10px 0px">
							<v-pagination
								@input="changePage($event)"
								color="#5b5b5e"
								v-model="page"
								:length="pages"
								:total-visible="7"
							></v-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['country', 'countryCode', 'companyType', 'master', 'topCategory'],
		data() {
			return {
				showGrid: false,
				message: 'Buscando',
				filterActivated: false,
				loading: false,
				page: 1,
				pages: 1,
				items: [],
				totalItems: 1,
				itemsPerPage: 20,
				cityId: '',
				categoryId: { id: 0, name: 'Todas' },
				selectedCountry: this.country,
				countries: [{ id: 0, name: 'Todos' }],
				categories: [{ id: 0, name: 'Todas' }],
				search: '',
				bannerUrl: '',
				searchRules: [(v) => v.length <= 3 || 'Longitud mínima de 3 caracteres'],
			}
		},
		watch: {
			country(newValue, oldValue) {
				switch (this.companyType) {
					case 1:
						this.selectedCountry = { id: 0, name: 'Todos' }
						break

					default:
						this.selectedCountry = newValue
						break
				}
				this.getCompanies()
				this.getCategories()
			},
			categories() {
				this.setDefaultFilters()
				if (this.countries.length > 1) {
					this.getCompanies()
				}
			},
			countries() {
				this.setDefaultFilters()
				if (this.categories.length > 1) {
					this.getCompanies()
				}
			},
			companyType(newValue, oldValue) {
				this.setDefaultFilters()
				this.getCompanies()
			},
			topCategory(newValue) {
				this.categoryId = newValue
				this.filterActivated = true
				this.getCompanies()
			},
			categoryId(newValue) {
				this.$route.query.category = newValue.id
			},
			selectedCountry(newValue) {
				this.$route.query.country = newValue.id
			},
		},
		components: {},
		created() {
			this.getCategories()
			this.getCountries()
		},
		computed: {
			companyLink() {
				switch (this.companyType) {
					case 0:
						if (this.master) {
							return '/franquicias-master/'
						} else {
							return '/franquicias/'
						}
					case 1:
						return '/asociaciones-franquicias/'
					case 2:
						return '/consultores/'
					case 3:
						return '/proveedores/'
					case 4:
						return '/oferta-inmobiliaria/'
					default:
						return '/franquicias/'
				}
			},
		},
		methods: {
			checkAppliedFilters() {
				if (this.$route.query.category) {
					this.categoryId = this.categories.find(
						(category) => category.id == parseInt(this.$route.query.category)
					)
				}
				if (this.$route.query.country) {
					this.selectedCountry = this.countries.find(
						(country) => country.id == parseInt(this.$route.query.country)
					)
				}
			},
			setDefaultFilters() {
				switch (this.companyType) {
					case 1:
						this.categoryId = this.categories.find((category) => category.id == 68)
						this.selectedCountry = { id: 0, name: 'Todos' }
						break
					case 4:
						this.categoryId = this.categories.find((category) => category.id == 1)
						this.selectedCountry = this.country
						break

					default:
						if (Object.keys(this.$route.query).length > 0) {
							this.checkAppliedFilters()
						} else {
							this.selectedCountry = this.country
							this.categoryId = { id: 0, name: 'Todos' }
						}
						break
				}
				this.search = ''
			},
			nameWithIso2({ name, iso2 }) {
				return `${name} (${iso2})`
			},
			async getCompanies() {
				if (!this.filterActivated) {
					this.setDefaultFilters()
				}
				this.message = 'Buscando'
				this.loading = true
				let searchStr = ''
				let countryStr = ''
				let categoryStr = ''
				let masterStr = ''
				if (this.master) {
					masterStr = '&master=1'
				}
				countryStr =
					this.selectedCountry && this.selectedCountry.id != 0
						? '&country_id=' + this.selectedCountry.id
						: ''
				searchStr = this.search != '' ? '&search=' + this.search : ''
				categoryStr =
					this.categoryId && this.categoryId.id != 0
						? '&category_id=' + this.categoryId.id
						: ''
				axios
					.get(
						process.env.VUE_APP_API_DIRECTORY +
							'companies-list?active=1&page=' +
							this.page +
							'&type=' +
							this.companyType +
							searchStr +
							countryStr +
							categoryStr +
							masterStr
					)
					.then((response) => {
						this.items = response.data.data
						if (this.items.length < 1) {
							this.message = 'No se encontraron resultados para esta búsqueda'
						}
						this.totalItems = response.data.total
						this.itemsPerPage = response.data.per_page
						this.pages = response.data.last_page
						this.loading = false
					})
			},

			async getCategories() {
				axios
					.get(process.env.VUE_APP_API_DIRECTORY + 'categories?limit=300&type=0')
					.then((response) => {
						this.categories = this.categories.concat(response.data)
						switch (this.companyType) {
							case 1:
								this.categoryId = this.categories.find((category) => category.id == 68)
								break
							case 4:
								this.categoryId = this.categories.find((category) => category.id == 1)
								break
							default:
								break
						}
					})
			},

			async getCountries() {
				axios
					.get(process.env.VUE_APP_API_DIRECTORY + 'countries?limit=300')
					.then((response) => {
						this.countries = this.countries.concat(response.data)
					})
			},

			async changePage(page) {
				this.page = page
				this.items = []
				this.getCompanies()
			},
		},
	}
</script>
<style lang="scss" scoped>
	// Responsive Variables
	$extra-large: 'only screen and (min-width: 1200px)';
	$large: 'only screen and (min-width: 992px) and (max-width: 1199px)';
	$medium: 'only screen and (min-width: 768px) and (max-width: 991px)';
	$small: 'only screen and (min-width: 576px) and (max-width: 767px)';
	$extra-small: 'only screen and (max-width: 599px)';
	.active {
		background-color: #003a56 !important;
		color: #fff !important;
	}
	.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
		> .v-input__control
		> .v-input__slot {
		border-radius: 50px;
	}

	// Search (filter section)

	.search__container {
		margin: 0;
		padding: 9px;
		border-radius: 0px;
		width: 100%;
		max-height: inherit;
		// box-shadow: 2px 2px 2px 2px rgb(51 51 51 / 15%);
		box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
		display: flex;
		align-items: flex-start;
	}
	.search__button-container {
		width: 100%;
		display: inline-flex;
		align-self: flex-end;
	}
	.search__button {
		color: #fff !important;
		background-color: #5b5b5e !important;
		align-self: center;
		height: 100%;
		min-width: 30px;
	}

	// Company Items

	.items__container {
		min-height: 9rem;
		margin: 20px 0;
		box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
		border-radius: 5px;
	}
	.item__img-container {
		height: 200px !important;
		display: flex;
		justify-content: center;
	}
	.item__img {
		object-fit: contain !important;
		width: 100%;
		height: 100%;
		min-height: 100px !important;
		align-self: center;
	}
	.content__description {
		width: 90%;
		margin: 1% 0;
	}

	// Grid Layout

	.list__item-content--grid {
		padding: 1rem 0rem 0rem 0rem;
	}
	.content__description--grid {
		width: 100%;
		margin: 0px !important;
	}
	.like-banner--grid {
		position: absolute;
		right: 20px;
		top: 20px;
	}
	.item__img-container--grid {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 200px;
		height: 100px !important;
	}

	.list__item-img--grid {
		padding: 5%;
		max-width: 100%;
		height: 100px;
	}
	.item__img--grid {
		object-fit: contain;
		height: 100%;
		width: 80%;
	}
	.like-banner {
		right: 3%;
		top: 5%;
	}

	.multiselect {
		box-sizing: content-box;
		display: block;
		position: relative;
		width: 100%;
		min-height: 38px;
		text-align: left;
		color: #35495e;
		box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
			0px 1px 5px 0px rgb(0 0 0 / 12%);
	}

	.theme--light.v-pagination .v-pagination__item--active {
		color: #ffffff;
		background-color: #c3c5ca;
	}
	.v-text-field.v-text-field--enclosed .v-text-field__details {
		padding-top: 0px;
		margin-bottom: 8px;
		display: none;
	}
	@media #{$extra-small} {
		.layout-switcher {
			display: none;
		}
		.search__button-container {
			justify-content: flex-end;
		}
		.list__container,
		.list__container--grid {
			margin: 0 2%;
		}
		.list__item-img {
			padding: 5%;
			display: flex;
			justify-content: center;
			align-content: center;
		}
		.item__img-container {
			width: 400px;
			height: 150px !important;
		}
		.item__img {
			object-fit: contain;
			height: 100%;
			width: 80%;
		}
		.list__item-content {
			padding: 5%;
		}
		.like-banner--mobile {
			right: 15px;
			top: -160px;
		}

		.content__description {
			width: 100%;
			margin: 5% 0;
		}
	}
</style>
